import _Sdp from "./Sdp";
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StartSessionRequestToJSON = exports.StartSessionRequestFromJSONTyped = exports.StartSessionRequestFromJSON = exports.instanceOfStartSessionRequest = void 0;
var Sdp_1 = _Sdp;
/**
 * Check if a given object implements the StartSessionRequest interface.
 */
function instanceOfStartSessionRequest(value) {
  return true;
}
exports.instanceOfStartSessionRequest = instanceOfStartSessionRequest;
function StartSessionRequestFromJSON(json) {
  return StartSessionRequestFromJSONTyped(json, false);
}
exports.StartSessionRequestFromJSON = StartSessionRequestFromJSON;
function StartSessionRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "sdp": json["sdp"] == null ? undefined : (0, Sdp_1.SdpFromJSON)(json["sdp"]),
    "sessionId": json["session_id"] == null ? undefined : json["session_id"]
  };
}
exports.StartSessionRequestFromJSONTyped = StartSessionRequestFromJSONTyped;
function StartSessionRequestToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "sdp": (0, Sdp_1.SdpToJSON)(value["sdp"]),
    "session_id": value["sessionId"]
  };
}
exports.StartSessionRequestToJSON = StartSessionRequestToJSON;
export default exports;
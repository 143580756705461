var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IceCandidateToJSON = exports.IceCandidateFromJSONTyped = exports.IceCandidateFromJSON = exports.instanceOfIceCandidate = void 0;
/**
 * Check if a given object implements the IceCandidate interface.
 */
function instanceOfIceCandidate(value) {
  return true;
}
exports.instanceOfIceCandidate = instanceOfIceCandidate;
function IceCandidateFromJSON(json) {
  return IceCandidateFromJSONTyped(json, false);
}
exports.IceCandidateFromJSON = IceCandidateFromJSON;
function IceCandidateFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "candidate": json["candidate"] == null ? undefined : json["candidate"],
    "sdpMLineIndex": json["sdpMLineIndex"] == null ? undefined : json["sdpMLineIndex"],
    "sdpMid": json["sdpMid"] == null ? undefined : json["sdpMid"],
    "usernameFragment": json["usernameFragment"] == null ? undefined : json["usernameFragment"]
  };
}
exports.IceCandidateFromJSONTyped = IceCandidateFromJSONTyped;
function IceCandidateToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "candidate": value["candidate"],
    "sdpMLineIndex": value["sdpMLineIndex"],
    "sdpMid": value["sdpMid"],
    "usernameFragment": value["usernameFragment"]
  };
}
exports.IceCandidateToJSON = IceCandidateToJSON;
export default exports;
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StopSessionRequestToJSON = exports.StopSessionRequestFromJSONTyped = exports.StopSessionRequestFromJSON = exports.instanceOfStopSessionRequest = void 0;
/**
 * Check if a given object implements the StopSessionRequest interface.
 */
function instanceOfStopSessionRequest(value) {
  return true;
}
exports.instanceOfStopSessionRequest = instanceOfStopSessionRequest;
function StopSessionRequestFromJSON(json) {
  return StopSessionRequestFromJSONTyped(json, false);
}
exports.StopSessionRequestFromJSON = StopSessionRequestFromJSON;
function StopSessionRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "sessionId": json["session_id"] == null ? undefined : json["session_id"]
  };
}
exports.StopSessionRequestFromJSONTyped = StopSessionRequestFromJSONTyped;
function StopSessionRequestToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "session_id": value["sessionId"]
  };
}
exports.StopSessionRequestToJSON = StopSessionRequestToJSON;
export default exports;
import _IceCandidate from "./IceCandidate";
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IceRequestToJSON = exports.IceRequestFromJSONTyped = exports.IceRequestFromJSON = exports.instanceOfIceRequest = void 0;
var IceCandidate_1 = _IceCandidate;
/**
 * Check if a given object implements the IceRequest interface.
 */
function instanceOfIceRequest(value) {
  return true;
}
exports.instanceOfIceRequest = instanceOfIceRequest;
function IceRequestFromJSON(json) {
  return IceRequestFromJSONTyped(json, false);
}
exports.IceRequestFromJSON = IceRequestFromJSON;
function IceRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "candidate": json["candidate"] == null ? undefined : (0, IceCandidate_1.IceCandidateFromJSON)(json["candidate"]),
    "sessionId": json["session_id"] == null ? undefined : json["session_id"]
  };
}
exports.IceRequestFromJSONTyped = IceRequestFromJSONTyped;
function IceRequestToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "candidate": (0, IceCandidate_1.IceCandidateToJSON)(value["candidate"]),
    "session_id": value["sessionId"]
  };
}
exports.IceRequestToJSON = IceRequestToJSON;
export default exports;
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskRequestToJSON = exports.TaskRequestFromJSONTyped = exports.TaskRequestFromJSON = exports.instanceOfTaskRequest = void 0;
/**
 * Check if a given object implements the TaskRequest interface.
 */
function instanceOfTaskRequest(value) {
  return true;
}
exports.instanceOfTaskRequest = instanceOfTaskRequest;
function TaskRequestFromJSON(json) {
  return TaskRequestFromJSONTyped(json, false);
}
exports.TaskRequestFromJSON = TaskRequestFromJSON;
function TaskRequestFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "text": json["text"] == null ? undefined : json["text"],
    "sessionId": json["session_id"] == null ? undefined : json["session_id"]
  };
}
exports.TaskRequestFromJSONTyped = TaskRequestFromJSONTyped;
function TaskRequestToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "text": value["text"],
    "session_id": value["sessionId"]
  };
}
exports.TaskRequestToJSON = TaskRequestToJSON;
export default exports;
import _TaskResponseData from "./TaskResponseData";
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TaskResponseToJSON = exports.TaskResponseFromJSONTyped = exports.TaskResponseFromJSON = exports.instanceOfTaskResponse = void 0;
var TaskResponseData_1 = _TaskResponseData;
/**
 * Check if a given object implements the TaskResponse interface.
 */
function instanceOfTaskResponse(value) {
  return true;
}
exports.instanceOfTaskResponse = instanceOfTaskResponse;
function TaskResponseFromJSON(json) {
  return TaskResponseFromJSONTyped(json, false);
}
exports.TaskResponseFromJSON = TaskResponseFromJSON;
function TaskResponseFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "code": json["code"] == null ? undefined : json["code"],
    "data": json["data"] == null ? undefined : (0, TaskResponseData_1.TaskResponseDataFromJSON)(json["data"]),
    "message": json["message"] == null ? undefined : json["message"]
  };
}
exports.TaskResponseFromJSONTyped = TaskResponseFromJSONTyped;
function TaskResponseToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "code": value["code"],
    "data": (0, TaskResponseData_1.TaskResponseDataToJSON)(value["data"]),
    "message": value["message"]
  };
}
exports.TaskResponseToJSON = TaskResponseToJSON;
export default exports;
import _runtime from "./runtime";
import _index from "./apis/index";
import _index2 from "./models/index";
var exports = {};
var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = exports && exports.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/* tslint:disable */
/* eslint-disable */
__exportStar(_runtime, exports);
__exportStar(_index, exports);
__exportStar(_index2, exports);
export default exports;
export const __esModule = exports.__esModule,
  TextApiResponse = exports.TextApiResponse,
  BlobApiResponse = exports.BlobApiResponse,
  VoidApiResponse = exports.VoidApiResponse,
  JSONApiResponse = exports.JSONApiResponse,
  canConsumeForm = exports.canConsumeForm,
  mapValues = exports.mapValues,
  querystring = exports.querystring,
  COLLECTION_FORMATS = exports.COLLECTION_FORMATS,
  RequiredError = exports.RequiredError,
  FetchError = exports.FetchError,
  ResponseError = exports.ResponseError,
  BaseAPI = exports.BaseAPI,
  DefaultConfig = exports.DefaultConfig,
  Configuration = exports.Configuration,
  BASE_PATH = exports.BASE_PATH,
  StreamingAvatarApi = exports.StreamingAvatarApi,
  IceCandidateToJSON = exports.IceCandidateToJSON,
  IceCandidateFromJSONTyped = exports.IceCandidateFromJSONTyped,
  IceCandidateFromJSON = exports.IceCandidateFromJSON,
  instanceOfIceCandidate = exports.instanceOfIceCandidate,
  IceRequestToJSON = exports.IceRequestToJSON,
  IceRequestFromJSONTyped = exports.IceRequestFromJSONTyped,
  IceRequestFromJSON = exports.IceRequestFromJSON,
  instanceOfIceRequest = exports.instanceOfIceRequest,
  IceResponseToJSON = exports.IceResponseToJSON,
  IceResponseFromJSONTyped = exports.IceResponseFromJSONTyped,
  IceResponseFromJSON = exports.IceResponseFromJSON,
  instanceOfIceResponse = exports.instanceOfIceResponse,
  NewSessionDataToJSON = exports.NewSessionDataToJSON,
  NewSessionDataFromJSONTyped = exports.NewSessionDataFromJSONTyped,
  NewSessionDataFromJSON = exports.NewSessionDataFromJSON,
  instanceOfNewSessionData = exports.instanceOfNewSessionData,
  NewSessionIceServers2ToJSON = exports.NewSessionIceServers2ToJSON,
  NewSessionIceServers2FromJSONTyped = exports.NewSessionIceServers2FromJSONTyped,
  NewSessionIceServers2FromJSON = exports.NewSessionIceServers2FromJSON,
  instanceOfNewSessionIceServers2 = exports.instanceOfNewSessionIceServers2,
  NewSessionRequestToJSON = exports.NewSessionRequestToJSON,
  NewSessionRequestFromJSONTyped = exports.NewSessionRequestFromJSONTyped,
  NewSessionRequestFromJSON = exports.NewSessionRequestFromJSON,
  instanceOfNewSessionRequest = exports.instanceOfNewSessionRequest,
  NewSessionRequestQualityEnum = exports.NewSessionRequestQualityEnum,
  NewSessionRequestVoiceToJSON = exports.NewSessionRequestVoiceToJSON,
  NewSessionRequestVoiceFromJSONTyped = exports.NewSessionRequestVoiceFromJSONTyped,
  NewSessionRequestVoiceFromJSON = exports.NewSessionRequestVoiceFromJSON,
  instanceOfNewSessionRequestVoice = exports.instanceOfNewSessionRequestVoice,
  NewSessionResponseToJSON = exports.NewSessionResponseToJSON,
  NewSessionResponseFromJSONTyped = exports.NewSessionResponseFromJSONTyped,
  NewSessionResponseFromJSON = exports.NewSessionResponseFromJSON,
  instanceOfNewSessionResponse = exports.instanceOfNewSessionResponse,
  SdpToJSON = exports.SdpToJSON,
  SdpFromJSONTyped = exports.SdpFromJSONTyped,
  SdpFromJSON = exports.SdpFromJSON,
  instanceOfSdp = exports.instanceOfSdp,
  SdpTypeEnum = exports.SdpTypeEnum,
  StartSessionRequestToJSON = exports.StartSessionRequestToJSON,
  StartSessionRequestFromJSONTyped = exports.StartSessionRequestFromJSONTyped,
  StartSessionRequestFromJSON = exports.StartSessionRequestFromJSON,
  instanceOfStartSessionRequest = exports.instanceOfStartSessionRequest,
  StartSessionResponseToJSON = exports.StartSessionResponseToJSON,
  StartSessionResponseFromJSONTyped = exports.StartSessionResponseFromJSONTyped,
  StartSessionResponseFromJSON = exports.StartSessionResponseFromJSON,
  instanceOfStartSessionResponse = exports.instanceOfStartSessionResponse,
  StopSessionRequestToJSON = exports.StopSessionRequestToJSON,
  StopSessionRequestFromJSONTyped = exports.StopSessionRequestFromJSONTyped,
  StopSessionRequestFromJSON = exports.StopSessionRequestFromJSON,
  instanceOfStopSessionRequest = exports.instanceOfStopSessionRequest,
  StopSessionResponseToJSON = exports.StopSessionResponseToJSON,
  StopSessionResponseFromJSONTyped = exports.StopSessionResponseFromJSONTyped,
  StopSessionResponseFromJSON = exports.StopSessionResponseFromJSON,
  instanceOfStopSessionResponse = exports.instanceOfStopSessionResponse,
  TaskRequestToJSON = exports.TaskRequestToJSON,
  TaskRequestFromJSONTyped = exports.TaskRequestFromJSONTyped,
  TaskRequestFromJSON = exports.TaskRequestFromJSON,
  instanceOfTaskRequest = exports.instanceOfTaskRequest,
  TaskResponseToJSON = exports.TaskResponseToJSON,
  TaskResponseFromJSONTyped = exports.TaskResponseFromJSONTyped,
  TaskResponseFromJSON = exports.TaskResponseFromJSON,
  instanceOfTaskResponse = exports.instanceOfTaskResponse,
  TaskResponseDataToJSON = exports.TaskResponseDataToJSON,
  TaskResponseDataFromJSONTyped = exports.TaskResponseDataFromJSONTyped,
  TaskResponseDataFromJSON = exports.TaskResponseDataFromJSON,
  instanceOfTaskResponseData = exports.instanceOfTaskResponseData;
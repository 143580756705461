var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SdpToJSON = exports.SdpFromJSONTyped = exports.SdpFromJSON = exports.instanceOfSdp = exports.SdpTypeEnum = void 0;
/**
 * @export
 */
exports.SdpTypeEnum = {
  Answer: "answer",
  Offer: "offer",
  Pranswer: "pranswer",
  Rollback: "rollback"
};
/**
 * Check if a given object implements the Sdp interface.
 */
function instanceOfSdp(value) {
  return true;
}
exports.instanceOfSdp = instanceOfSdp;
function SdpFromJSON(json) {
  return SdpFromJSONTyped(json, false);
}
exports.SdpFromJSON = SdpFromJSON;
function SdpFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "sdp": json["sdp"] == null ? undefined : json["sdp"],
    "type": json["type"] == null ? undefined : json["type"]
  };
}
exports.SdpFromJSONTyped = SdpFromJSONTyped;
function SdpToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "sdp": value["sdp"],
    "type": value["type"]
  };
}
exports.SdpToJSON = SdpToJSON;
export default exports;
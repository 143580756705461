import _NewSessionIceServers from "./NewSessionIceServers2";
import _Sdp from "./Sdp";
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewSessionDataToJSON = exports.NewSessionDataFromJSONTyped = exports.NewSessionDataFromJSON = exports.instanceOfNewSessionData = void 0;
var NewSessionIceServers2_1 = _NewSessionIceServers;
var Sdp_1 = _Sdp;
/**
 * Check if a given object implements the NewSessionData interface.
 */
function instanceOfNewSessionData(value) {
  return true;
}
exports.instanceOfNewSessionData = instanceOfNewSessionData;
function NewSessionDataFromJSON(json) {
  return NewSessionDataFromJSONTyped(json, false);
}
exports.NewSessionDataFromJSON = NewSessionDataFromJSON;
function NewSessionDataFromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "sessionId": json["session_id"] == null ? undefined : json["session_id"],
    "sdp": json["sdp"] == null ? undefined : (0, Sdp_1.SdpFromJSON)(json["sdp"]),
    "iceServers": json["ice_servers"] == null ? undefined : json["ice_servers"],
    "iceServers2": json["ice_servers2"] == null ? undefined : json["ice_servers2"].map(NewSessionIceServers2_1.NewSessionIceServers2FromJSON)
  };
}
exports.NewSessionDataFromJSONTyped = NewSessionDataFromJSONTyped;
function NewSessionDataToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "session_id": value["sessionId"],
    "sdp": (0, Sdp_1.SdpToJSON)(value["sdp"]),
    "ice_servers": value["iceServers"],
    "ice_servers2": value["iceServers2"] == null ? undefined : value["iceServers2"].map(NewSessionIceServers2_1.NewSessionIceServers2ToJSON)
  };
}
exports.NewSessionDataToJSON = NewSessionDataToJSON;
export default exports;
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NewSessionIceServers2ToJSON = exports.NewSessionIceServers2FromJSONTyped = exports.NewSessionIceServers2FromJSON = exports.instanceOfNewSessionIceServers2 = void 0;
/**
 * Check if a given object implements the NewSessionIceServers2 interface.
 */
function instanceOfNewSessionIceServers2(value) {
  return true;
}
exports.instanceOfNewSessionIceServers2 = instanceOfNewSessionIceServers2;
function NewSessionIceServers2FromJSON(json) {
  return NewSessionIceServers2FromJSONTyped(json, false);
}
exports.NewSessionIceServers2FromJSON = NewSessionIceServers2FromJSON;
function NewSessionIceServers2FromJSONTyped(json, ignoreDiscriminator) {
  if (json == null) {
    return json;
  }
  return {
    "credential": json["credential"] == null ? undefined : json["credential"],
    "credentialType": json["credentialType"] == null ? undefined : json["credentialType"],
    "username": json["username"] == null ? undefined : json["username"],
    "urls": json["urls"] == null ? undefined : json["urls"]
  };
}
exports.NewSessionIceServers2FromJSONTyped = NewSessionIceServers2FromJSONTyped;
function NewSessionIceServers2ToJSON(value) {
  if (value == null) {
    return value;
  }
  return {
    "credential": value["credential"],
    "credentialType": value["credentialType"],
    "username": value["username"],
    "urls": value["urls"]
  };
}
exports.NewSessionIceServers2ToJSON = NewSessionIceServers2ToJSON;
export default exports;
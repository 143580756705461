import _runtime from "../runtime";
import _index from "../models/index";
var exports = {};
/* tslint:disable */
/* eslint-disable */
/**
 * Streaming Avatar SDK
 * Heygen Streaming Avatar
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: api@heygen.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = exports && exports.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StreamingAvatarApi = void 0;
var runtime = _runtime;
var index_1 = _index;
/**
 *
 */
var StreamingAvatarApi = /** @class */function (_super) {
  __extends(StreamingAvatarApi, _super);
  function StreamingAvatarApi() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * This call is encapsulated by createAndStartAvatar, only use this for advanced applications
   * Opens and returns a new streaming avatar session
   */
  StreamingAvatarApi.prototype.createStreamingAvatarRaw = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var queryParameters, headerParameters, _a, _b, response;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            if (requestParameters["newSessionRequest"] == null) {
              throw new runtime.RequiredError("newSessionRequest", "Required parameter \"newSessionRequest\" was null or undefined when calling createStreamingAvatar().");
            }
            queryParameters = {};
            headerParameters = {};
            headerParameters["Content-Type"] = "application/json";
            if (!(this.configuration && this.configuration.apiKey)) return [3 /*break*/, 2];
            _a = headerParameters;
            _b = "X-API-KEY";
            return [4 /*yield*/, this.configuration.apiKey("X-API-KEY")];
          case 1:
            _a[_b] = _c.sent(); // api_key authentication
            _c.label = 2;
          case 2:
            return [4 /*yield*/, this.request({
              path: "/v1/streaming.new",
              method: "POST",
              headers: headerParameters,
              query: queryParameters,
              body: (0, index_1.NewSessionRequestToJSON)(requestParameters["newSessionRequest"])
            }, initOverrides)];
          case 3:
            response = _c.sent();
            return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) {
              return (0, index_1.NewSessionResponseFromJSON)(jsonValue);
            })];
        }
      });
    });
  };
  /**
   * This call is encapsulated by createAndStartAvatar, only use this for advanced applications
   * Opens and returns a new streaming avatar session
   */
  StreamingAvatarApi.prototype.createStreamingAvatar = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.createStreamingAvatarRaw(requestParameters, initOverrides)];
          case 1:
            response = _a.sent();
            return [4 /*yield*/, response.value()];
          case 2:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  /**
   * Pass text to be repeated by avatar
   */
  StreamingAvatarApi.prototype.speakRaw = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var queryParameters, headerParameters, _a, _b, response;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            if (requestParameters["taskRequest"] == null) {
              throw new runtime.RequiredError("taskRequest", "Required parameter \"taskRequest\" was null or undefined when calling speak().");
            }
            queryParameters = {};
            headerParameters = {};
            headerParameters["Content-Type"] = "application/json";
            if (!(this.configuration && this.configuration.apiKey)) return [3 /*break*/, 2];
            _a = headerParameters;
            _b = "X-API-KEY";
            return [4 /*yield*/, this.configuration.apiKey("X-API-KEY")];
          case 1:
            _a[_b] = _c.sent(); // api_key authentication
            _c.label = 2;
          case 2:
            return [4 /*yield*/, this.request({
              path: "/v1/streaming.task",
              method: "POST",
              headers: headerParameters,
              query: queryParameters,
              body: (0, index_1.TaskRequestToJSON)(requestParameters["taskRequest"])
            }, initOverrides)];
          case 3:
            response = _c.sent();
            return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) {
              return (0, index_1.TaskResponseFromJSON)(jsonValue);
            })];
        }
      });
    });
  };
  /**
   * Pass text to be repeated by avatar
   */
  StreamingAvatarApi.prototype.speak = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.speakRaw(requestParameters, initOverrides)];
          case 1:
            response = _a.sent();
            return [4 /*yield*/, response.value()];
          case 2:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  /**
   * This call is encapsulated by createAndStartAvatar, only use this for advanced applications
   * Starts the streaming avatar session. Must be called after creation.
   */
  StreamingAvatarApi.prototype.startStreamingAvatarRaw = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var queryParameters, headerParameters, _a, _b, response;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            if (requestParameters["startSessionRequest"] == null) {
              throw new runtime.RequiredError("startSessionRequest", "Required parameter \"startSessionRequest\" was null or undefined when calling startStreamingAvatar().");
            }
            queryParameters = {};
            headerParameters = {};
            headerParameters["Content-Type"] = "application/json";
            if (!(this.configuration && this.configuration.apiKey)) return [3 /*break*/, 2];
            _a = headerParameters;
            _b = "X-API-KEY";
            return [4 /*yield*/, this.configuration.apiKey("X-API-KEY")];
          case 1:
            _a[_b] = _c.sent(); // api_key authentication
            _c.label = 2;
          case 2:
            return [4 /*yield*/, this.request({
              path: "/v1/streaming.start",
              method: "POST",
              headers: headerParameters,
              query: queryParameters,
              body: (0, index_1.StartSessionRequestToJSON)(requestParameters["startSessionRequest"])
            }, initOverrides)];
          case 3:
            response = _c.sent();
            return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) {
              return (0, index_1.StartSessionResponseFromJSON)(jsonValue);
            })];
        }
      });
    });
  };
  /**
   * This call is encapsulated by createAndStartAvatar, only use this for advanced applications
   * Starts the streaming avatar session. Must be called after creation.
   */
  StreamingAvatarApi.prototype.startStreamingAvatar = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.startStreamingAvatarRaw(requestParameters, initOverrides)];
          case 1:
            response = _a.sent();
            return [4 /*yield*/, response.value()];
          case 2:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  /**
   * Stops the streaming avatar session. This endpoint has been exposed to support advanced applications. To ensure the RTC session is correctly closed, please use stopAvatar instead.
   */
  StreamingAvatarApi.prototype.stopSessionRaw = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var queryParameters, headerParameters, _a, _b, response;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            if (requestParameters["stopSessionRequest"] == null) {
              throw new runtime.RequiredError("stopSessionRequest", "Required parameter \"stopSessionRequest\" was null or undefined when calling stopSession().");
            }
            queryParameters = {};
            headerParameters = {};
            headerParameters["Content-Type"] = "application/json";
            if (!(this.configuration && this.configuration.apiKey)) return [3 /*break*/, 2];
            _a = headerParameters;
            _b = "X-API-KEY";
            return [4 /*yield*/, this.configuration.apiKey("X-API-KEY")];
          case 1:
            _a[_b] = _c.sent(); // api_key authentication
            _c.label = 2;
          case 2:
            return [4 /*yield*/, this.request({
              path: "/v1/streaming.stop",
              method: "POST",
              headers: headerParameters,
              query: queryParameters,
              body: (0, index_1.StopSessionRequestToJSON)(requestParameters["stopSessionRequest"])
            }, initOverrides)];
          case 3:
            response = _c.sent();
            return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) {
              return (0, index_1.StopSessionResponseFromJSON)(jsonValue);
            })];
        }
      });
    });
  };
  /**
   * Stops the streaming avatar session. This endpoint has been exposed to support advanced applications. To ensure the RTC session is correctly closed, please use stopAvatar instead.
   */
  StreamingAvatarApi.prototype.stopSession = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.stopSessionRaw(requestParameters, initOverrides)];
          case 1:
            response = _a.sent();
            return [4 /*yield*/, response.value()];
          case 2:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  /**
   * This call is encapsulated by createAndStartAvatar, only use this for advanced applications
   * Submit the ICE candidate. Dependent on active session.
   */
  StreamingAvatarApi.prototype.submitICECandidateRaw = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var queryParameters, headerParameters, _a, _b, response;
      return __generator(this, function (_c) {
        switch (_c.label) {
          case 0:
            if (requestParameters["iceRequest"] == null) {
              throw new runtime.RequiredError("iceRequest", "Required parameter \"iceRequest\" was null or undefined when calling submitICECandidate().");
            }
            queryParameters = {};
            headerParameters = {};
            headerParameters["Content-Type"] = "application/json";
            if (!(this.configuration && this.configuration.apiKey)) return [3 /*break*/, 2];
            _a = headerParameters;
            _b = "X-API-KEY";
            return [4 /*yield*/, this.configuration.apiKey("X-API-KEY")];
          case 1:
            _a[_b] = _c.sent(); // api_key authentication
            _c.label = 2;
          case 2:
            return [4 /*yield*/, this.request({
              path: "/v1/streaming.ice",
              method: "POST",
              headers: headerParameters,
              query: queryParameters,
              body: (0, index_1.IceRequestToJSON)(requestParameters["iceRequest"])
            }, initOverrides)];
          case 3:
            response = _c.sent();
            return [2 /*return*/, new runtime.JSONApiResponse(response, function (jsonValue) {
              return (0, index_1.IceResponseFromJSON)(jsonValue);
            })];
        }
      });
    });
  };
  /**
   * This call is encapsulated by createAndStartAvatar, only use this for advanced applications
   * Submit the ICE candidate. Dependent on active session.
   */
  StreamingAvatarApi.prototype.submitICECandidate = function (requestParameters, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var response;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.submitICECandidateRaw(requestParameters, initOverrides)];
          case 1:
            response = _a.sent();
            return [4 /*yield*/, response.value()];
          case 2:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  /**
   * This call both terminates the streaming avatar session and closes the RTC connection
   */
  StreamingAvatarApi.prototype.stopAvatar = function (requestParameters, debugStream, initOverrides) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var debug;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (this.peerConnection) {
              this.peerConnection.close();
            }
            debug = new Debug(debugStream);
            return [4 /*yield*/, this.stopSession(requestParameters.stopSessionRequest.sessionId ? requestParameters : {
              stopSessionRequest: {
                sessionId: (_a = this.sessionId) !== null && _a !== void 0 ? _a : ""
              }
            }, initOverrides).catch(function (error) {
              debug.print(JSON.stringify(error));
            })];
          case 1:
            _b.sent();
            debug.print("Stopped session successfully.");
            return [2 /*return*/];
        }
      });
    });
  };
  /**
   * This call creates and starts a new streaming avatar session
   */
  StreamingAvatarApi.prototype.createStartAvatar = function (requestParameters, debugStream, initOverrides) {
    return __awaiter(this, void 0, void 0, function () {
      var convertToRTCIceServer, convertToRTCSessionDescription, debug, onMessage, data, serverSdp, iceServers, remoteDescription, localDescription, error_1;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            convertToRTCIceServer = function (iceServers) {
              var rtcs = [];
              iceServers.forEach(function (server) {
                var rtc = {
                  urls: server.urls,
                  username: server.username,
                  credential: server.credential
                };
                rtcs.push(rtc);
              });
              return rtcs;
            };
            convertToRTCSessionDescription = function (serverSdp) {
              return new RTCSessionDescription({
                sdp: serverSdp.sdp,
                type: serverSdp.type
              });
            };
            debug = new Debug(debugStream);
            onMessage = function (event) {
              var message = event.data;
              debug.print("STREAMING AVATAR: Received message: ".concat(message));
            };
            _a.label = 1;
          case 1:
            _a.trys.push([1, 7,, 8]);
            debug.print("Creating a new session...");
            return [4 /*yield*/, this.createStreamingAvatar(requestParameters, initOverrides)];
          case 2:
            data = _a.sent().data;
            serverSdp = data.sdp, iceServers = data.iceServers2;
            this.sessionId = data.sessionId;
            this.peerConnection = new RTCPeerConnection({
              iceServers: convertToRTCIceServer(iceServers)
            });
            this.peerConnection.ontrack = function (event) {
              if (event.track.kind === "audio" || event.track.kind == "video") {
                _this._mediaStream = event.streams[0];
              }
            };
            // When receiving a message, display it in the status element
            this.peerConnection.ondatachannel = function (event) {
              var dataChannel = event.channel;
              dataChannel.onmessage = onMessage;
            };
            remoteDescription = new RTCSessionDescription(convertToRTCSessionDescription(serverSdp));
            return [4 /*yield*/, this.peerConnection.setRemoteDescription(remoteDescription)];
          case 3:
            _a.sent();
            debug.print("Session creation complete.");
            if (!data) {
              throw Error("STREAMING AVATAR: Issue with created session");
            }
            debug.print("Starting the session...");
            return [4 /*yield*/, this.peerConnection.createAnswer()];
          case 4:
            localDescription = _a.sent();
            return [4 /*yield*/, this.peerConnection.setLocalDescription(localDescription)];
          case 5:
            _a.sent();
            this.peerConnection.onicecandidate = function (_a) {
              var candidate = _a.candidate;
              return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_b) {
                  if (candidate) {
                    this.submitICECandidate({
                      iceRequest: {
                        sessionId: this.sessionId,
                        candidate: {
                          candidate: candidate.candidate,
                          sdpMid: candidate.sdpMid,
                          sdpMLineIndex: candidate.sdpMLineIndex,
                          usernameFragment: candidate.usernameFragment
                        }
                      }
                    }).then(function (c) {
                      return __awaiter(_this, void 0, void 0, function () {
                        var _this = this;
                        return __generator(this, function (_a) {
                          // When ICE connection state changes, display the new state
                          this.peerConnection.oniceconnectionstatechange = function (_event) {
                            debugStream("ICE connection state changed to: ".concat(_this.peerConnection.iceConnectionState));
                          };
                          return [2 /*return*/];
                        });
                      });
                    }).catch(function (error) {
                      debug.print(JSON.stringify(error));
                    });
                  }
                  return [2 /*return*/];
                });
              });
            };

            return [4 /*yield*/, this.startStreamingAvatar({
              startSessionRequest: {
                sdp: localDescription,
                sessionId: this.sessionId
              }
            })];
          case 6:
            _a.sent();
            debug.print("Session started successfully");
            return [2 /*return*/, data];
          case 7:
            error_1 = _a.sent();
            this.peerConnection.close();
            return [3 /*break*/, 8];
          case 8:
            return [2 /*return*/];
        }
      });
    });
  };

  Object.defineProperty(StreamingAvatarApi.prototype, "mediaStream", {
    get: function () {
      return this._mediaStream;
    },
    enumerable: false,
    configurable: true
  });
  return StreamingAvatarApi;
}(runtime.BaseAPI);
exports.StreamingAvatarApi = StreamingAvatarApi;
var Debug = /** @class */function () {
  function Debug(debug) {
    this._debug = debug;
  }
  Debug.prototype.print = function (text) {
    if (!this._debug) {
      return;
    }
    this._debug(text);
  };
  return Debug;
}();
export default exports;